<!-- *************************** 个人中心 ****************************** -->
<template>
  <div class="admin">
    <div class="container">
      <div class="admin_header">
        <Avatar />
        <div class="wallet_box">
          <p class="wallet">钱包余额：{{user_credit/100}} AUD</p>
          <p class="wallet">推广奖励金额：{{reward_credit/100}} AUD</p>
          <router-link to="/admin_center/member_recharge">
            <button class="admin_avatar_recharge">充值</button>
          </router-link>
        </div>
      </div>
      <div class="content" v-loading="!$store.state.userInfo.name">
        <el-container>
          <Aside></Aside>
          <div style="width:80%">
            <router-view></router-view>
          </div>
        </el-container>
      </div>
    </div>
  </div>
</template>
<script>
import Aside from "./Aside";
import Avatar from "./Avatar";
import { getWalletInfoApi } from "api/Recharge";
export default {
  components: {
    Aside,
    Avatar
  },
  data(){
    return{
      user_credit:'',
      reward_credit:'',
    }
  },
  mounted() {
    //获取钱包信息
    getWalletInfoApi().then(res => {
      if (res.code == 0) {
        const { user_credit,reward_credit } = res.data;
        this.user_credit = user_credit;
        this.reward_credit = reward_credit;
      }
    });
  }
};
</script>
<style lang="scss" scoped>
.admin {
  .admin_header {
    display: flex;
    justify-content: space-between;
    .wallet_box {
      padding: 30px 0;
      .wallet {
        color: #517cfc;
        margin-bottom: 10px;
      }
      button {
        width: 90px;
        height: 30px;
        background: #f7b500;
        border-radius: 3px;
        font-size: 14px;
        color: #fff;
      }
    }
  }

  .top {
    padding: 70px 0;
    background: #2c4670;
    color: #fff;
    font-size: 34px;
  }
  .content {
    min-height: 600px;
    margin: 80px 0 100px 0;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    border: 1px solid #e4e7ed;
  }
}
</style>